import React, { useState } from 'react';
import './Members.css'; // Ensure this CSS file is imported

const Members = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleModalToggle = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <>
            <section id="members" className="members-section">
                <h2>Join Web5 Community</h2>
                <p>Become a part of the Web5 revolution! Sign up to become a member and contribute to the future of decentralized technologies.</p>
                <button className="join-button" onClick={handleModalToggle}>Become a Member</button>
            </section>

            <section id="who-can-join" className="who-can-join-section">
                <h2>Who Can Join?</h2>
                <p>
                    Companies and organizations that are actively working or planning to provide services in the Web5 field are welcome to join.
                    Whether you're a startup, a tech firm, or an established company, if your services focus on enhancing Web5 technology through innovative solutions, you're eligible to be part of this transformative movement.
                </p>
                <h3>Eligible Companies Include:</h3>
                <ul>
                    <li>Blockchain technology providers</li>
                    <li>Data storage and management platforms</li>
                    <li>Security and privacy solution developers</li>
                    <li>Cloud and decentralized storage services</li>
                    <li>Software development firms focused on Web5 integrations</li>
                    <li>Consultancy firms in the Web5 ecosystem</li>
                    <li>AI and big data analytics companies with Web5 solutions</li>
                </ul>
                <p>
                    Join us in building a more secure, transparent, and powerful internet that combines the strengths of both centralized and decentralized technologies.
                </p>
            </section>

            <section id="members-list">
                <h3>Current Members</h3>
                <ul className="members-list">
                    <li>Blockchain Innovators Inc.</li>
                    <li>DataSecure Solutions</li>
                    <li>PrivacyGuard Technologies</li>
                    <li>Decentralized Cloud Services</li>
                    <li>FutureWeb Integrations</li>
                </ul>
            </section>

            {isModalOpen && (
                <div className="modal-overlay" onClick={handleModalToggle}>
                    <div className="modal-content" onClick={e => e.stopPropagation()}>
                        <h2>Become a Member</h2>
                        <form>
                            <div className="form-group">
                                <label htmlFor="companyName">Name of the Company:</label>
                                <input type="text" id="companyName" name="companyName" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="phoneNumber">Phone Number:</label>
                                <input type="tel" id="phoneNumber" name="phoneNumber" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email Address:</label>
                                <input type="email" id="email" name="email" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="activities">List of Activities and Services:</label>
                                <textarea id="activities" name="activities" rows="4" required></textarea>
                            </div>
                            <div className="form-group">
                                <label htmlFor="city">City of Main Office:</label>
                                <input type="text" id="city" name="city" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="website">Website:</label>
                                <input type="url" id="website" name="website" />
                            </div>
                            <button type="submit" className="submit-button">Submit</button>
                        </form>
                        <button className="close-button" onClick={handleModalToggle}>Close</button>
                    </div>
                </div>
            )}
        </>
    );
};

export default Members;
