import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './components/Home/Home';
import Services from './components/Services/Services';
import Members from './components/Members/Members';
import './App.css';
import Footer from './components/Footer/Footer';

function App() {
  return (
    <Router>
      <div className="App">
        <header>
          <h1>
          <Link to="/" className="logo">Web5</Link>

            </h1>
          <nav>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/services">Services</Link></li>
              <li><Link to="/members">Members</Link></li>
            </ul>
          </nav>
        </header>

        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/members" element={<Members />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
