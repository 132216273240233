import React from 'react';
import './Services.css'; // Make sure to import the CSS file

const Services = () => {
    return (
        <section id="services" className="services-section">
            <h2>Our Services</h2>
            <p>We are dedicated to building a strong foundation for companies operating in the Web5 ecosystem. Our services include:</p>
            <ul>
                <li>Web5 Platform Development: Create robust platforms that leverage the advantages of Web5 technology.</li>
                <li>Blockchain Integration: Seamlessly integrate blockchain solutions to enhance security and transparency.</li>
                <li>Decentralized Application Solutions: Develop decentralized applications that are efficient and user-friendly.</li>
                <li>Membership Program: Companies can apply to become members, providing them access to exclusive resources and updates on Web5 advancements.</li>
                <li>Workshops and Training: Regular workshops to educate members on Web5 practices and implementations.</li>
            </ul>
        </section>
    );
};

export default Services;
