import React from 'react';
import './Home.css'; // Make sure to import the CSS file

const Home = () => {
    return (
        <>
            <section id="home" className="hero-section">
                <h2>Welcome to Web5</h2>
                <p>Web5 is the future, combining the best of Web2 and Web3 to create a decentralized and secure internet experience.</p>
            </section>
            <section id="what-is-web5" className="info-section">
                <h2>What is Web5?</h2>
                <p>
                    Web5 is the future of the internet that combines the best of Web2 and Web3.
                    It merges the centralized data capabilities of Web2 with the decentralized power of Web3, creating a safer, more scalable, and privacy-focused internet.
                    In this new paradigm, large data sets are stored efficiently using traditional systems (Web2), while blockchain technology (Web3) ensures secure and transparent transactions.
                    This hybrid model ensures that businesses can take advantage of both centralized and decentralized technologies, offering a more flexible and reliable web experience.
                </p>
            </section>
        </>
    );
};

export default Home;
